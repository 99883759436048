<template>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";

export default {
  name: "MenuTabs",
  data: () => ({
    optionCode: enums.webSiteOptions.MENU_TABS,
    habilitaPantallaInicioConfig: false,
    allowedActions: null,
    canEdit: false
  }),
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadParameters();
  },
  methods: {
    ...mapActions({
      getParameters: "configAppBenef/getParameters",
    }),
    async loadParameters() {
      const data = await this.getParameters();
      this.habilitaPantallaInicioConfig = data.habilitaPantallaInicioConfigurable;
      if(this.habilitaPantallaInicioConfig){
        this.$router.push({
          name: "MenuTabsJoin",
          params: {
            canEdit: this.canEdit
          }
        });
      }else{
        this.$router.push({
          name: "MenuTabsSeparated",
          params: {
            canEdit: this.canEdit
          }
        });
      }
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.EDITAR_MENU_TABS:
            this.canEdit = true;
            break;
          default:
            break;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
